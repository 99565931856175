export const locale = {
    lang: 'es',
    data: {
        'MFA': {
            'TITLE': 'Autenticación en 2 pasos (MFA)'
        },
        'NAV': {
            'DASHBOARDS': 'Dashboards',
            'ADMIN': 'Admin',
            'REPORTE': 'Reporte',
            'BALANCEV2': {
                'TITULO': 'Balance',
                'PAYINS': 'Balance de Liquidación Payin',
                'EXTENDIDO': 'Balance Liq. Extendido'
            },
            'PAYMENT_LINK': 'Link de Pagos',
            'CHAT': 'Chat',
            'CONTACTS': 'Contacts',
            'DASH': {
                'RESUMEN': 'Resumen',
            },
            'REPORT': {
                'CONSULTA': 'Consulta de operaciones',
                'TRANSACCION': 'Reporte de Transacciones',
                'OPERATIVO': 'Reporte Operativo',
                'ACTUALIZACION': 'Actualización Masiva',
                'ARCHIVOS': 'Reporte Archivos',
                'EXTENDIDO': 'Reporte de liquidación',
            },
            'ADM': {
                'USUARIOS': 'Lista de Usuarios',
                'MERCHANT': 'Datos del comercio',
                'COMERCIOS': 'Lista de Comercios',
                'GRUPOS': 'Lista de Grupos',
                'PUSHPAYS': 'Módulo Push Pays',
                'TARIFAS': 'Tarifas',
                'TARIFAS_NUEVO': 'Tarifas Nuevo',
                'RUTEO': 'Ruteo',
                'TRASLADO': 'Traslado de Efectivo - ARG',
                'TRASLADO_SUBTITLE': 'Lista de tarifas traslado de efectivo por procesadora',
                'SUBMENU_CONVERSION': 'Conversión USD',
            }
        },

        'WIDGET_SUPPORT': {
            'WELCOME_MESSAGE': 'Le damos la bienvenida al servicio de soporte Monnet',
            'CONTACT_US': 'Contáctenos',
            'NAME': 'Su nombre',
            'EMAIL': 'Su dirección de correo electrónico',
            'SUBJECT': 'Asunto',
            'DESCRIPTION': 'Descripción',
            'MAX_FILE_SIZE_MESSAGE': 'El tamaño máximo de los archivos es de 20MB',
            'MAX_FILE_QUANTITY_MESSAGE': 'Solo puede subir hasta 5 archivos',
            'SUPPORT': 'Soporte',
            'SENDING': 'Enviando solicitud...',
            'SEND': 'Enviar',
            'MAX_FILE_INFO': 'Cargar archivos (máx. 5 archivos)',
            'FILE_CLICK': 'Haga click para subir un archivo',
            'COMPLETE_REQUIRED_FIELD': 'Complete los requerimientos de los campos',
            'SUCCESS_MESSAGE': 'Mensaje de soporte creado exitosamente.',
            'ERROR_MESSAGE': 'Error al crear el mensaje de soporte.',
            'FILE_ALLOWED': 'Archivos permitidos',
            'ATACHED_FILES': 'Archivos adjuntos',
        },

        'ELEM_GENERIC': {
            'BUSCAR': 'Buscar',
            'LIMPIAR': 'Limpiar',
            'AGREGAR': 'Agregar',
            'BUSCAR_COMERCIO': 'Buscar comercio',
            'BUSCAR_GRUPO': 'Buscar grupo',
            'TARIFAS': 'Buscar tarifas',
            'COMERCIO': 'Buscar comercio',
            'ACTIVAR': 'Activar',
            'ELIMINAR': 'Eliminar',
            'CANCELAR': 'Cancelar',
            'ACTUALIZAR': 'Actualizar',
            'CONFIRMAR': 'Confirmar',
            'REQUIRED_FIELD': 'Completar los requerimientos del campo',
            'EDITAR': 'Editar',
            'CERRAR': 'Cerrar',
            'REGISTRAR': 'Registrar',
            'DESACTIVAR': 'Desactivar',
            'CONFIGURAR': 'Configurar',
            'TASAS_CAMBIO': 'Tasas de Cambio',
            'AGREGAR_TASA': 'Agregar Tasa',
            'COMPLETE_REQUIRED_FIELD': 'Completar campos requeridos',
            'NO_DATA_EXPORT': 'No se encontraron datos para exportar',
            'NO_DATA_FOUND': 'No se encontraron datos',
            'GUARDAR': 'Guardar',
            'GENERAR': 'Generar',
            'MONEDA': 'Moneda',
            'FECHA_INI': 'Fecha Inicio',
            'FECHA_INI_CONTRACARGO': 'Fecha Inicio Contracargo',
            'FECHA_FIN_CONTRACARGO': 'Fecha Fin Contracargo',
            'FECHA_FIN': 'Fecha Fin',
            'ESTADO': 'Estado',
            'FECHA_PAGO': 'Fecha de pago',
            'FECHA_CONTRACARGO': 'Fecha de contracargo',
            'FECHA_ATORIZACION': 'Fecha de Autorización',
            'DESCARGAR_EXCEL': 'Descargar Excel',
            'DESCARGAR_CSV': 'Descargar Csv',
            'FILTRO_DATOS': 'Filtro de datos',
            'ESPERE': 'Espere por favor  ...',
            'ELEGIR_COMERCIO': 'Elegir Comercio',
            'ACCION': 'Acción',
            'ERROR_3MESES': 'Debe ingresar un período menor a 90 días',
            'ERROR_UNEXPECTED': 'Error inesperado',
            'ERROR_EMPTY': 'No se encontraron datos en la fecha comprendida',
            'ACTIVATE_MULTIPROCESSOR_TO_CONTINUE': 'Activar multiprocesadora para configurar',
            'ACTIVATE_CONNECTION_TO_CONTINUE': 'Activar conexion directa para configurar',
            'PAYMENT_METHOD_CONFIGURATION_MESSAGE': 'No se encontraron metodos de pago',
            'PROCESSOR_CONFIGURATION_MESSAGE': 'No se encontraron procesadoras',
            'CONFIGURATE_PAYMENT_METHOD': 'Configuracion de metodo de pago',
            'EDIT_CONFIGURATE_PAYMENT_METHOD': 'Editar configuracion de metodo de pago',
            'EDIT_CONFIGURATE_DIRECT_CONNECTIONS': 'Editar Procesador / Colector',
            'CONTRACARGO': "Contracargo",
            "ESTADO_ALERTA": "Estado de la alerta",
            "FECHA_ESTADO_ALERTA_INICIO": "Fecha de estado de la alerta inicio",
            "FECHA_ESTADO_ALERTA_FIN": "Fecha de estado de la alerta fin"
        },
        'DASHBBOARD': {
            'CANT_TOTAL_TRANS': 'Cantidad Total de Transacciones',
            'MONTO_TOTAL_TRANS': 'Monto Total de Transacciones',
            'MONTO_TRANSACCIONES_SEMANA': 'Monto de Transacciones por Día (últimos 7 días)',
            'MONTO_TOTAL_TRANS_CANAL': 'Monto Total de Transacciones por Canal',
            'MONTO_TOT_SEMANA': {
                'TX': '#Tx:',
                'TX_PROM': '#Ticket Prom:',
                'TX_COMPLETADO': '#Tx. Completadas:'
            }
        },
        'REPORTE': {
            'BASE': {
                'TITULO': 'Reporte',
                'TITULO_TRANSACCIONES': "Reporte de transacciones",
                'TITULO_OPERATIVO': "Reporte operativo",
                'TABLE_COMERCIO': 'Comercio',
                'TABLE_ORIGEN': 'Origen',
                'TABLE_ID': 'Id Operación',
                'TABLE_ID_COMERCIO': 'Id Operación Comercio',
                'TABLE_MONEDA': 'Moneda',
                'TABLE_MONTO': 'Monto',
                'TABLE_MONTO_ORI': 'Monto Original',
                'TABLE_PROCESADOR': 'Procesador de Pago',
                'TABLE_REDPAGO': 'Red de Pago',
                'TABLE_FECHA_REG': 'Fecha/hora de Registro',
                'TABLE_FECHA_LIQU': 'Fecha de liquidación',
                'TABLE_FECHA_COMP': 'Fecha de Compensación',
                'TABLE_ESTADO': 'Estado',
                'TABLE_ERROR_DETAIL': 'Info. transacción',
                'TABLE_CANAL': 'Canal de Pago',
                'TABLE_FECHA_PAG': 'Fecha de Pago',
                'TABLE_FECHA_AUT': 'Fecha/hora de Autorización',
                'TABLE_FECHA_PUSH': 'Fecha/hora de PushPay',
                'TABLE_TARIFA': 'Fee',
                'TABLE_IMPUESTO': 'Impuesto',
                'TABLE_FEE_AND_TAX': 'Total Fee & Impuesto',
                'TABLE_NOMBRE': 'Nombre Cliente',
                'TABLE_NAME': 'Nombre',
                'TABLE_LAST_NAME': 'Apellidos',
                'TABLE_LAST_EMAIL': 'Correo',
                'TABLE_LAST_PAN': 'Last Pan',
                'TABLE_BANCO': 'Banco',
                'TABLE_CARD_TYPE': 'Tipo de Tarjeta',
                'TABLE_BRAND': 'Marca Tarjeta',
                'TABLE_INSTALLMENT': 'Cuotas',
                'TABLE_NET_MERCHANT': 'Monto a Liquidar',
                'TABLE_PROCESSING_TYPE': 'Tipo Procesamiento',
                'TABLE_NOTIFICATION_STATUS': 'Estado Notificación',
                'TABLE_ID_CLIENTE': 'Id Cliente Comercio',
                'TABLE_PAYMENT_CODE': 'Código de pago',
                'TABLE_TICKET_NUMBER': 'Número de ticket',
                'TABLE_PROCESSOR_ID': 'ID procesador',
                'TABLE_IS_CHARGEBACK': 'Contracargo',
                'TABLE_RETENCION_IVA': 'Ret IVA',
                "TABLE_RETENCION_CRED_Y_DEV": "Ret Deb&Cred",
                "TABLE_RETENCION_UTILIDADES": "Ret Utilidades",
                "TABLE_RETENCION_TOTAL_RETENCIONES": "Total retenciones",
                "TABLE_RETENCION_TRASLADO_EFECTIVO": "Traslado de efectivo",
                "TABLE_RETENCION_NET_TO_MERCHANT": "Net to merchant",
                "TABLE_RETENCION_TOTAL_TARIFA_E_IMPUESTO": "Total Tarifa & Impuesto",
                "TABLE_BIN": "Bin"
            },
            'BASE_EXPORT_FILE': {
                'FILE_NAME': 'Reporte',
                'SHEET_NAME_OPERATIVE': 'Reporte Operativo',
                'SHEET_NAME_TRANSACTION': 'Reporte de Transacciones',
                'COLUMN_COMERCIO': 'Comercio',
                'COLUMN_ORIGEN': 'Origen',
                'COLUMN_ID': 'Id Operación',
                'COLUMN_ID_COMERCIO': 'Id Operación Comercio',
                'COLUMN_MONEDA': 'Moneda',
                'COLUMN_MONTO': 'Monto',
                'COLUMN_MONTO_ORI': 'Monto Original',
                'COLUMN_PROCESADOR': 'Procesador de Pago',
                'COLUMN_FECHA_REG': 'Fecha/hora de Registro',
                'COLUMN_ESTADO': 'Estado',
                'COLUMN_ERROR_DETAILS': 'Info. transacción',
                'COLUMN_CANAL': 'Canal de Pago',
                'COLUMN_FECHA_PAG': 'Fecha/hora de Pago',
                'COLUMN_FECHA_AUT': 'Fecha/hora de Autorización',
                'COLUMN_FECHA_LIQU': 'Fecha de liquidación',
                'COLUMN_FECHA_COMP': 'Fecha de compensación',
                'COLUMN_FECHA_PUSH': 'Fecha/hora de PushPay',
                'COLUMN_TARIFA': 'Fee',
                'COLUMN_IMPUESTO': 'Impuesto',
                'COLUMN_NOMBRE': 'Nombre Cliente',
                'COLUMN_NAME': 'Nombre',
                'COLUMN_LAST_NAME': 'Apellidos',
                'COLUMN_LAST_EMAIL': 'Correo',
                'COLUMN_LAST_PAN': 'Last Pan',
                'COLUMN_CARD_TYPE': 'Tipo de Tarjeta',
                'COLUMN_BRAND': 'Marca tarjeta',
                'COLUMN_INSTALLMENT': 'Cuotas',
                'COLUMN_NET_MERCHANT': 'Monto a Liquidar',
                'COLUMN_PROCESSING_TYPE': 'Tipo Procesamiento',
                'COLUMN_NOTIFICATION_STATUS': 'Estado Notificación',
                'COLUMN_ID_CLIENTE': 'Id Cliente Comercio',
                'COLUMN_PAYMENT_CODE': 'Código de pago',
                'COLUMN_TICKET_NUMBER': 'Número de ticket',
                'COLUMN_PROCESSOR_ID': 'ID procesador',
                'COLUMN_FEE_AND_TAX': 'Total Fee & Impuesto',
                'COLUMN_CHARGEBACK': 'Contracargo',
                'COLUMN_CHARGEBACKDATE': 'Fecha de Contracargo',
                'COLUMN_RETENCION_IVA': 'Ret IVA',
                "COLUMN_RETENCION_DEB_CRED": "Ret Deb&Cred",
                "COLUMN_RETENCION_UTILIDADES": "Ret Profits",
                "COLUMN_TOTAL_RETENCIONES": "Total Retention",
                "COLUMN_TRASLADO_EFECTIVO": "Cash transfer",
            },
            'BASE_EXPORT_OPERATIVE_FILE': {
                'FILE_NAME': 'Reporte Operativo',
                'SHEET_NAME_OPERATIVE': 'Reporte Operativo',
                'COLUMN_COMERCIO': 'COMERCIO',
                'COLUMN_ORIGEN': 'ORIGEN',
                'COLUMN_ID': 'ID_OPERACION',
                'COLUMN_ID_COMERCIO': 'ID_OPERACION_COMERCIO',
                'COLUMN_MONEDA': 'MONEDA',
                'COLUMN_MONTO': 'MONTO',
                'COLUMN_MONTO_ORI': 'MONTO ORIGINAL',
                'COLUMN_PROCESADOR': 'COD_PROCESADOR',
                'COLUMN_REDPAGO': 'RED_PAGO',
                'COLUMN_FECHA_REG': 'FECHA_REGISTRO',
                'COLUMN_ESTADO': 'ESTADO',
                'COLUMN_ERROR_DETAILS': 'Info. transacción',
                'COLUMN_CANAL': 'CANAL',
                'COLUMN_FECHA_PAG': 'FECHA_PAGO',
                'COLUMN_FECHA_AUT': 'FECHA_AUTORIZACION',
                'COLUMN_FECHA_LIQU': 'FECHA_LIQUIDACION',
                'COLUMN_FECHA_COMP': 'FECHA_COMPENSACION',
                'COLUMN_FECHA_PUSH': 'FECHA_PUSHPAY',
                'COLUMN_TARIFA': 'FEE',
                'COLUMN_IMPUESTO': 'IMPUESTO',
                'COLUMN_NAME': 'NOMBRE',
                'COLUMN_LAST_NAME': 'APELLIDOS',
                'COLUMN_LAST_EMAIL': 'CORREO',
                'COLUMN_LAST_PAN': 'ULTIMOS_DIGITOS_TARJETA',
                'COLUMN_BANK': 'BANCO',
                'COLUMN_CARD_TYPE': 'TIPO_TARJETA',
                'COLUMN_BRAND': 'MARCA_TARJETA',
                'COLUMN_INSTALLMENT': 'CUOTAS',
                'COLUMN_NET_MERCHANT': 'NET_MERCHANT',
                'COLUMN_PROCESSING_TYPE': 'TIPO_PROCESAMIENTO',
                'COLUMN_NOTIFICATION_STATUS': 'ESTADO_NOTIFICACION',
                'COLUMN_PAYMENT_CODE': 'Código de pago',
                'COLUMN_TICKET_NUMBER': 'Número de ticket',
                'COLUMN_PROCESSOR_ID': 'ID procesador',
                'COLUMN_CHARGEBACK': 'Contracargo',
                'COLUMN_CHARGEBACKDATE': 'Fecha de Contracargo',
                'COLUMN_RETENCION_IVA': 'Ret IVA',
                "COLUMN_RETENCION_DEB_CRED": "Ret Deb&Cred",
                "COLUMN_RETENCION_UTILIDADES": "Ret Profits",
                "COLUMN_TOTAL_RETENCIONES": "Total Retention",
                "COLUMN_TRASLADO_EFECTIVO": "Cash transfer",
            },
            'ACTUALIZACION_MASIVA': {
                'TITULO': 'Actualización Masiva',
                'SUBTITULO': 'Actualización Masiva',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_NAME': 'Nombre del archivo',
                    "TABLA_ESTADO_CARGA": "Estado de archivo en la carga",
                    'TABLA_FECHA': 'Fecha de registro',
                    'TABLA_ESTADO': 'Estado del archivo',
                    'TABLA_USUARIO': 'Usuario',
                    "TABLA_ARCHIVO_ENTRADA": "Archivo entrada"
                },

                'PUSH_PAY':{
                    'TITULO': 'Push Pay',
                    'SUBTITULO': 'Push Pay',
                    'LISTA': {
                        'TABLA_ID': 'ID',
                        'TABLA_NAME': 'Nombre del Archivo',
                        'TABLA_ESTADO_CARGA': 'Estado de archivo en la carga',
                        'TABLA_USUARIO': 'Usuario',
                        'TABLA_FECHA': 'Fecha de Registro',
                        'TABLA_CANTIDAD': 'Cantidad de Push Pay',
                        'TABLA_ESTADO': 'Estado de archivo',
                        'TABLA_ARCHIVO': 'Archivo entrada',
                        'TABLA_ACCIONES': 'Acciones',
                    }
                }
            },
            
            'REPORTE_POR_ARCHIVOS': {
                'TITULO': 'Reporte',
                'SUB_TITULO': 'Reporte por Archivo',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_NAME_PAYDAY': 'Archivo Fecha de Autorización',
                    'TABLA_NAME_REGISTRATION': 'Archivo Fecha de Registro',
                    'TABLA_REGISTRY_DATE': 'Fecha y Hora de Registro',
                    'TABLA_PROGRAMMING_TYPE': 'Tipo de Programación'
                }
            }
        },
        'ADMIN': {
            'LISTA_USUARIOS': {
                'TITULO': 'Usuarios',
                'SUBTITULO': 'Lista de Usuarios',
                'TABLE_NOM_USUARIO': 'Nombre de Usuario',
                'TABLE_NOMBRE': 'Nombre',
                'TABLE_APELLIDO': 'Apellido',
                'TABLE_CORREO': 'Correo Electronico',
                'TABLE_ROL': 'Rol',
                'TABLE_ESTADO': 'Estado',
                'TABLE_ACCIONES': 'Acciones',
                'MODAL': {
                    'TITULO_NUEVO_USU': 'Nuevo Usuario',
                    'TITULO_MODIF_USU': 'Editar Usuario',
                    'NOMBRE_DE_USU': 'Nombre de Usuario *',
                    'CONTRASENA': 'Contraseña *',
                    'CORREO_USU': 'Correo Electronico *',
                    'NOMBRES_USU': 'Nombres *',
                    'APELLIDOS_USU': 'Apellidos *',
                    'DOCUMENTO_USU': 'Documento',
                    'NRO_DOCUMENTO_USU': 'Nro Documento',
                    'ELEGIR_COMERCIO_USU': 'Elegir Comercio *',
                    'ROL_USU': 'Rol *',
                    'GUARDAR_USU': 'Guardar',
                    'MODIFICAR_USU': 'Modificar'
                }
            },
            'COMERCIO': {
                'TITULO': 'Comercio',
                'SUBTITULO': 'Datos del Comercio',
                'LLAVE_SHA': 'LLAVE',
                'URL_RESPUESTA': 'URL Notificación',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_TIPO': 'Tipo',
                    'TABLA_KEY': 'Llave',
                    'TABLA_ESTADO': 'Estado',
                    'TABLA_FECHA': 'Fecha de Registro',

                }
            },
            'COMERCIOS': {
                'TITULO': 'Comercios',
                'SUBTITULO': 'Lista de Comercios',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_NAME': 'Nombre',
                    'TABLA_ESTADO': 'Estado',
                    'CONFIG_MULTIPROCESSOR': 'Configurar Multiprocesador',
                    'CONFIG_CONECTION': 'Configurar Procesador / Colector',
                    'CONFIG_NOTIFICATION': 'Configurar Notificaciones',
                    'TABLA_EMAIL': 'Email',
                    'TABLA_PAIS': 'País',
                    'TABLA_GRUPO': 'Grupo',
                    'TABLA_URL': 'Url',
                    'TABLA_FECHA': 'Fecha de Registro'
                }
            },
            'GRUPOS': {
                'TITULO': 'Grupos',
                'SUBTITULO': 'Lista de Grupos',
                'MODAL': {
                    'TITULO_NUEVO_GRUPO': 'Nuevo Grupo',
                    'TITULO_EDITAR_GRUPO': 'Editar Grupo',
                    'NOMBRE_GRUPO': 'Nombre de Grupo',
                    'GUARDAR_GRUPO': 'Guardar',
                    'MODIFICAR_GRUPO': 'Modificar'
                },
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_NAME': 'Nombre',
                    'TABLA_FECHA': 'Fecha de Registro'
                }
            },
            'PUSHPAYS': {
                'BASE': {
                    'TITULO': 'Módulo Push Pay',
                    'SUB_TITULO': 'Push-Pays',
                    'APROBACION_TITULO': 'Aprobación de Push-Pays',
                    'TABLE_COMERCIO': 'Comercio',
                    'TABLE_ID': 'Id Operación',
                    'TABLE_IDPUSH': 'Id Push-Pay',
                    'TABLE_ID_COMERCIO': 'Id Operación Comercio',
                    'TABLE_MONEDA': 'Moneda',
                    'TABLE_MONTO': 'Monto',
                    'TABLE_MONTO_ORI': 'Monto Original',
                    'TABLE_PROCESADOR': 'Procesador de Pago',
                    'TABLE_FECHA_REG': 'Fecha/hora de Registro',
                    'TABLE_FECHA_PUR': 'Fecha/hora de Compra',
                    'TABLE_ESTADO': 'Estado Push-Pay',
                    'TABLE_ESTADO2': 'Estado',
                    'TABLE_ESTADO_ANTERIOR': 'Estado anterior',
                    'TABLE_ESTADO_NUEVO': 'Estado nuevo',
                    'TABLE_CANAL': 'Canal de Pago',
                    'TABLE_FECHA_PAG': 'Fecha/Hora de Push-Pay',
                    'TABLE_FECHA_PAG_REG': 'Fecha Registro',
                    'TABLE_TARIFA': 'Fee',
                    'TABLE_IMPUESTO': 'Impuesto',
                    'TABLE_NOMBRE': 'Nombre Cliente',
                    'TABLE_USUARIO': 'Usuario',
                    'ACCIONES': {
                        'PUSH': 'Push-Pay',
                        'NOTIFICAR': 'Notificar',
                        'EJECUTAR_PUSH': 'Ejecutar Push-Pay',
                        'VER_PUSHPAYS': 'Ver Push-Pays',
                        'APROBAR': 'Aprobar',
                        'RECHAZAR': 'Rechazar',
                        'VOLVER': 'Volver',
                    },
                },
                'EXPORT': {
                    'SHEET_NAME_OPERATIVE': 'Reporte Push Pay',
                    'COLUMN_COMERCIO': 'Comercio',
                    'COLUMN_ID': 'Id Operación',
                    'COLUMN_IDPUSH': 'Id Push-Pay',
                    'COLUMN_ID_COMERCIO': 'Id Operación Comercio',
                    'COLUMN_MONEDA': 'Moneda',
                    'COLUMN_MONTO': 'Monto',
                    'COLUMN_FECHA_REG': 'Fecha/Hora de Registro',
                    'COLUMN_FECHA_PUSHPAY': 'Fecha/Hora de Push-Pay',
                    'COLUMN_FECHA_PUR': 'Fecha/hora de Compra',
                    'COLUMN_ESTADO': 'Estado Push-Pay',
                    'COLUMN_ESTADO_ANTERIOR': 'Estado anterior',
                    'COLUMN_ESTADO_NUEVO': 'Estado nuevo',
                    'COLUMN_DESCRIPCION': 'Motivo/Detalle',
                    'COLUMN_USUARIO': 'Usuario'
                },
                'MODAL': {
                    'TITULO': 'Push-Pay',
                    'DESCRIPCION': 'Motivo/Detalle',
                    'ESTADO': 'Estado',
                    'COMERCIO': 'Comercio',
                    'ID_COMERCIO': 'Id Operación Comercio',
                    'ID': 'Id Operación',
                    'MONTO': 'Monto',
                    'MONEDA': 'Moneda',
                    'FECHA_REG': 'Fecha/hora de Registro',
                }
            },
            'TARIFAS': {
                'TITULO': 'Tarifas',
                'SUBTITULO': 'Lista de Tarifas',
                'MERCHANT': 'Comercio',
                'PAYMENT_METHOD': 'Método de Pago',
                'CURRENCY': 'Moneda',
                'VAR_RATE': 'Tarifa variable',
                'MIN_RATE': 'Tarifa Mínima',
                'TAX': 'Impuesto',
                'FIXED_TAX': 'Tarifa fija',
                'ADITIONAL_COST': 'Costos adicionales',
                'STATUS': 'Estado',
                'SAVE': 'Guardar',
                'CANCEL': 'Cancelar',
                'NEW': 'Nueva',
                'UPDATE': 'Modificar'
            },
            'TARIFAS_TABLA':{
                'TITLE': 'Tarifas',
                'MERCHANT': 'Comercio',
                'PAYMENT_METHOD': 'Método de Pago',
                'CURRENCY': 'Moneda',
                'TAX': 'Impuesto',
                'TIERS': 'Nro Tiers',
                'USD': 'Negociada USD',
                'STATUS': 'Estado',
                'ENABLE': 'Habilitar/Inhabilitar',
                'ACTION': 'Acciones',
                'PAGINATOR': 'Tarifas por página',
            },

            "TARIFAS_TPV": {
                "TITULO": "Configuración TPV",
                "BOTON_DESCARGAR_PLANTILLA": "Descargar plantilla",
                "TABLA": {
                    "MES": "Mes",
                    "NOMBRE_ARCHIVO": "Nombre del archivo",
                    "USUARIO": "Usuario",
                    "FECHA_CARGA": "Fecha de carga",
                    "ESTADO": "Estado",
                    "CARGA_ARCHIVO": "Carga de archivo",
                    "ESTADO_PROCESADO": "Procesado"
                },
              
                "MODAL_CARGA_ARCHIVO": {
                    "TITULO": "Cargar archivo",
                    "PLACEHOLDER_INPUT": "Arrasta o selecciona archivo CSV.",
                    "DISCALIMER": "Revisa el archivo antes de guardar, ya que para realizar cambios tendrás que comunicarte con soporte.",
                    "BOTON_CARGAR": "Cargar",
                    "TITULO_CONFIRMACION": "¿Estás seguro de realizar esta operación?",
                    "DETALLE_ARCHIVO": "Se procederá a subir y guardar el archivo",
                    "DISCALIMER_SOPORTE": "Si más adelante deseas realizar cambios, tendrás que comunicarte con soporte",
                    "BOTON_REGRESAR": "Regresar",
                    "TITULO_EN_PROCESO": "Carga en proceso",
                    "TITULO_NOMBRE_ARCHIVO": "Subiste el archivo",
                    "TITULO_EXITO": "Archivo procesado",
                    "TITULO_ERROR": "Ups!",
                    "MENSAJE_ERROR": "Hay algún error. Revisa que todos los datos esten completos, que el ID del comercio exista y que este tenga la tarifa configurada para la monéda y el método de pago que indicas. Debes volver a cargar un archivo para este registro."
                },
                "MODAL_CAMBIAR_ESTADO": {
                    "TITULO": "Confirmación",
                    "TEXTO_HABILITAR": "¿Deseas habilitar la tarifa?",
                    "TEXTO_DESHABILITAR": "¿Deseas deshabilitar la tarifa?",
                    "BTN_CANCELAR": "Cancelar",
                    "BTN_ACEPTAR": "Aceptar"
                }

            },
            "CONFIGURACION_TARIFA": {
                "TITULO": "Tarifas",

                },
            'RUTEO': {
                'TITULO': 'Ruteo',
                'PAIS': 'País',
                'RAZON': 'Razon Social',
                'TIPO': 'Tipo de Ruteo',
                'REGLA': 'Regla',
                'TABLE_REGLA': 'Reglas',
                'TABLE_ESTADO': 'Estado',
                'TABLE_ACCION': 'Acción',
                'TABLE_METODO_PAGO': 'Metodo de pago',
                'TABLE_NRO_PROCESADORES': 'Nro. Procesadores',
                'MULTIPROCESSOR': 'Multiprocesadora',
                'RULES_ASSIGNMENT': 'Asignación de Reglas',
                'PAYMENT_COLLECTOR': 'Payment Collector',
                'CONFIGURAR': {
                    'TITLE': 'Configuración',
                    'REGLA': 'Regla',
                    'MIN_PROCESADORES': 'Minimo de Procesadoras',
                    'MAX_PROCESADORES': 'Maximo de Procesadoras',
                    'GUARDAR': 'Guardar',
                    'CANCELAR': 'Cancelar',
                    'PROCESADORA': 'Procesadora',
                    'MONEDA': 'Moneda',
                    'DISTRIBUCION_PORCENTAJE': {
                        'TITLE': 'Configuracion de parametros',
                        'PORCENTAJE': 'Porcentaje%'
                    },
                    'DISTRIBUCION_AMOUNT_TRX': {
                        'TITLE': 'Configuracion de parametros',
                        'MONTO_MINIMO': 'Monto Mínimo',
                        'MONTO_MAXIMO': 'Monto Máximo'
                    },
                    'DISTRIBUCION_HORA': {
                        'TITLE': 'Configuracion de parametros',
                        'HORA_INICIO': 'Hora inicio',
                        'HORA_FIN': 'Hora fin'
                    },
                    'DISTRIBUCION_MONTO_ACUMULADO': {
                        'TITLE': 'Configuracion de parametros',
                        'MONTO_MAXIMO': 'Monto Máximo',
                        'PRIORIDAD': 'Prioridad'
                    }
                }
            },
            'LINK_DE_PAGOS': {
                'TITULO': 'Link de Pago',
                'SUBTITULO': 'Generación de Link de Pago',
                'TITULO_CLIENTE': 'Datos del cliente',
                'TITULO_PAGO': 'Datos del pago',
                'TITULO_METODO': 'Método de pago',
                'TITULO_RESUMEN': 'Resumen de la operación',
                'GENERACION':{
                    'TITULO': 'Generación de Link de Pago',
                    'NOMBRE': 'Nombre',
                    'APELLIDO': 'Apellido',
                    'TIPO_DOC': 'Tipo de Documento',
                    'NRO_DOC': 'Número de Documento',
                    'EMAIL': 'Email',
                    'MONTO': 'Monto',
                    'MONEDA': 'Moneda',
                    'MERCHANT_ID': 'ID del Comercio',
                    'METODO_PAGO': 'Método de Pago',
                    'FECHA': 'Fecha de Vencimiento',
                    'OPERACION_ID': 'Id de operación',
                    'TIEMPO_EXPIRACION': 'Tiempo de expiración (Minutos)',
                    'DESCRIPCION': 'Descripción',
                    'GENERAR': 'Generar Link',
                    'CANCELAR': 'Cancelar',
                    'ERROR_HEADER': 'Error en la generación',
                    // 'ERROR_TITLE': 'La generación no se ha realizado, por favor revisa los siguientes detalles:'
                    'ERROR_TITLE': 'Ocurrió un error, por favor vuelva a intentarlo más tarde...'
                },
                'CONFIGURACION':{
                    'TITULO': 'Configuración de Link de Pago',
                    'SUBTITULO': 'Configuración',
                    'PAYIN_TRANSACTION_OK_URL': 'Transacción OK URL',
                    'PAYIN_TRANSACTION_ERROR_URL': 'Transacción Error URL',
                    'PAYIN_LANGUAGE': 'Idioma',
                    'PAYIN_CUSTOMER_PHONE': 'Teléfono',
                    'PAYIN_CUSTOMER_ADDRESS': 'Dirección',
                    'PAYIN_CUSTOMER_CITY': 'Ciudad',
                    'PAYIN_CUSTOMER_REGION': 'Región',
                    'PAYIN_CUSTOMER_COUNTRY': 'País',
                    'PAYIN_CUSTOMER_ZIP_CODE': 'Código Postal',
                    'PAYIN_CUSTOMER_SHIPPING_PHONE': 'Teléfono de Envío',
                    'PAYIN_CUSTOMER_SHIPPING_ADDRESS': 'Dirección de Envío',
                    'PAYIN_CUSTOMER_SHIPPING_CITY': 'Ciudad de Envío',
                    'PAYIN_CUSTOMER_SHIPPING_REGION': 'Región de Envío',
                    'PAYIN_CUSTOMER_SHIPPING_COUNTRY': 'País de Envío',
                    'PAYIN_CUSTOMER_SHIPPING_ZIP_CODE': 'Código Postal de Envío',
                    'PAYIN_REGULAR_CUSTOMER': 'Cliente Regular',
                    'PAYIN_DISCOUNT_COUPON': 'Cupón de Descuento',
                    'PAYIN_FILTER_BY': 'Filtrar por',
                    'PAYIN_PRODUCT_ID': 'ID del Producto',
                    'PAYIN_PRODUCT_DESCRIPTION': 'Descripción del Producto',
                    'PAYIN_PRODUCT_AMOUNT': 'Monto del Producto',
                    'PAYIN_PRODUCT_SKU': 'SKU del Producto',
                    'PAYIN_PRODUCT_QUANTITY': 'Cantidad del Producto',
                    'MERCHANT_NAME': 'Nombre del Comercio',
                    'SAVE': 'Guardar configuración',
                    'ERROR_HEADER': 'Error en la configuración',
                    'ERROR_TITLE': 'La configuración no se ha guardado, por favor revisa los siguientes detalles:'
                },
                'TABLA': {
                    'TITULO': 'Listado de Links Generados',
                    'FECHA_GENERACION': 'Fecha de Generación',
                    'USUARIO': 'Usuario',
                    'ID_OPERACION': 'ID operación comercio',
                    'MONTO': 'Monto',
                    'MONEDA': 'Moneda',
                    'ESTADO': 'Estado',
                    'NOMBRE': 'Nombre cliente',
                    'APELLIDO': 'Apellido cliente',
                    'EMAIL': 'Correo electrónico',
                    'LINK_DE_PAGO': 'Link de Pago',
                    'ARCHIVO':'Reporte links de Pago',
                },
                'PROCESAMIENTO':{
                    'TITULO': 'Procesamiento masivo',
                }
            },
            'TRASLADO': {
                'CREAR': 'Nuevo Registro',
                'VOLVER': 'Volver',
                'TITULO': 'Traslado Efectivo',
                'PROCESADORA_SELECT': 'Seleccione la procesadora para configurar la red de pago',
                'GUARDAR_REDES': 'Guardar Redes de Pago',
                'TABLA_PROCESADORAS':{
                    'NOMBRE': 'Nombre procesadora',
                    'FECHA': 'Fecha creación registro',
                    'ACCIONES': 'Acciones',
                    'EDITAR': 'Editar',
                    'VISUALIZAR': 'Visualizar'
                },
                'TABLA_TRASLADO':{
                    'ID_REGISTRO': 'ID Registro',
                    'CODIGO_RED': 'Código Red de pago',
                    'NOMBRE_RED': 'Nombre Red de pago',
                    'TIPO': 'Tipo tarifa',
                    'FECHA_CREACION': 'Fecha de creación',
                    'TARIFA': 'Tarifa red de pago',
                    'ACCIONES': 'Acciones',
                },
                'FORMULARIO':{
                    'TITULO_CREAR': 'Completa los datos para configurar la tarifa de traslado',
                    'ID_REGISTRO': 'ID Registro',
                    'CODIGO_RED': 'Código Red de pago',
                    'NOMBRE_RED': 'Nombre Red de pago',
                    'TIPO': 'Tipo',
                    'TARIFA': 'Tarifa red de pago',
                    'BOTON_GUARDAR': 'Agregar',
                    'BOTON_CANCELAR': 'Cancelar',
                    'BOTON_EDITAR': 'Modificar',
                    'BOTON_VISUALIZAR': 'Aceptar',
                    'INFO':{
                        'ID_RED_DE_PAGO': 'El ID de la red de pago es obligatorio',
                        'NOMBRE_RED_DE_PAGO': 'El nombre de la red de pago es obligatorio',
                        'CODIGO_RED_DE_PAGO': 'El Código de la red de pago es obligatorio',
                        'TIPO_RED_DE_PAGO': 'El tipo de la red de pago es obligatorio',
                        'TARIFA_RED_DE_PAGO': 'La tasa de la red de pago debe tener un maximo de 8 caracteres y es obligatoria'
                    }
                }
                
            }
        },
        'BALANCEV2': {
            'BASE': {
                'TITULO': 'Balance Liquidación Payins',
                'TABLE_COUNTRY': 'País',
                'TABLE_MERCHANT': 'Comercio',
                'TABLE_TRANSACTION_STATE': 'Estado',
                'TABLE_TOTAL_DAY_TRANSACTIONS': 'Cant. Trans Totales Día',
                'TABLE_TRANSACTION_DATE': 'Fecha Transacción Date',
                'TABLE_SETTLEMENT_DATE': 'Fecha Liquidación',
                'TABLE_CURRENCY': 'Moneda',
                'TABLE_PROCCESSED_TRANS_QTY': 'Cant. Trans. Procesadas',
                'TABLE_TPV_BILLINGS': 'TpvBlillings',
                'TABLE_FEE': 'Fee',
                'TABLE_TAX': 'Tax',
                'TABLE_NET_TO_MERCHANT': 'Net To Merchant',
                'TABLE_SETTLED_TRANS_QTY': 'Settled Trans. Qty.',
                'TABLE_SETTLEMENT_TYPE': 'Tipo Liquidación',
                'TABLE_SETTLEMENT_CODE': 'Código Liquidación',
                'TABLE_IN_PROCESS': 'En Proceso',
                'TABLE_DOWNLOAD': 'Descarga',
                'BOX_IN_PROCESS': 'Total En Proceso',
                'BOX_TOTAL_PROCESSED': 'Total Procesado',
                'SUMMARY_TEXT': 'Suma sobre todos los registros filtrados',
                'SUMMATORY_TEXT_PRINT': 'Montos Totales:'
            }
        },
        'PAYMENT_LINK': {
            'BASE': {
                'GENERATION_TITLE': 'Generación de Link de Pago',
                'CONFIGURATION_TITLE': 'Configuración de Link de Pago',
                'LIST_TITLE': 'Listado de Links Generados',
                'PROCESS_TITLE': 'Procesamiento masivo',
            },
            'PROCESS': {
                'FILTERS':{
                    'USER': 'Usuario',
                    'STATE': 'Estado',
                    'INIT_DATE': 'Fecha Inicio',
                    'END_DATE': 'Fecha Fin',
                    'PLACEHOLDER': 'Escriba aquí...',
                },
                'ACTIONS':{
                    'CREATE': 'Crear links masivos'
                },
                'TABLE':{
                    'ID': 'ID',
                    'FILE_NAME': 'Nombre del archivo',
                    'USER': 'Usuario',
                    'DATE': 'Fecha de registro',
                    'DATE_PROCESS': 'Fecha de procesamiento',
                    'STATE': 'Estado',
                    'FILE_IN': 'Archivo entrada',
                    'TOOLTIP_FILE_IN': 'Aquí puedes descargar el archivo que importaste para tu solicitud de links de pagos masivos.',	
                    'FILE_OUT': 'Archivo salida',
                    'TOOLTIP_FILE_OUT': 'Aquí puedes descargar el archivo con el detalle de los links de pagos masivos.',
                
                },
                'DIALOG':{
                    'TITLE': 'Link de pago masivo',
                    'MESSAGE': 'Genera varios links de pago, en un mismo momento, importando* un documento en formato .csv a través del botón <strong>“Importar archivo”</strong>.',
                    'ALERT': 'Descarga la <a>guía</a> de como crear el archivo para importarlo al backoffice.', 
                    'MIN_ALERT': '*Tener en cuenta que la cantidad máxima de registros permitidos es de 500 por solicitud.',   
                    'IMPORT': 'Importar archivo',
                    'DOWNLOAD_TEMPLATE': 'Descargar plantilla',

                    'TITLE_UPLOAD': 'Cargar archivo',
                    'IMPORTANT': '¡Importante!',
                    'LIST_1': 'Tener en cuenta que la cantidad máxima de registros permitidos es de <strong>500 por solicitud</strong>.',
                    'LIST_2': 'Después de guardar el archivo no podrás realizar cambios.',
                    

                    'TITLE_DETAIL': 'Archivo cargado',
                    'SUCCESS_MESSAGE': 'Importaste con éxito el archivo:',
                    'ANSWER': '¿En cuánto tiempo el archivo estará disponible? El tiempo de procesamiento del documento depende del número de registros. Mantente atento.',
                    

                    'ERROR': 'Ups! Ocurrió un problema',
                    'ERROR_SUBTITLE': 'Ocurrio un error al momento de cargar el archivo.',
                    'RETRY': 'Intenta nuevamente',


                    'CANCEL': 'Cancelar',
                    'UPLOAD': 'Cargar',
                    'UPLOAD_NEW_FILE': 'Importar Nuevo Archivo',
                    'OK': 'Entendido',
                }
                }
        },
        'INPUT':{
            'PLACEHOLDER': 'Escriba aquí...',
        },
        'QUESTIONNAIRE': {
            'TITLE': '¡Queremos escucharte!',
            'SUBTITLE_1': 'Responde a la ',  
            'SUBTITLE_2': 'Encuesta de Satisfacción NPS',
            'DATE': 'Del 02 al 31 de diciembre.',
            'ACTION': '¡EMPECEMOS!>', 
        }
    }
};
